/* eslint-disable */
import React, { forwardRef } from 'react';
import { Box } from 'reflexbox';

const Button = forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="button"
    tx="buttons"
    variant="primary"
    {...props}
    __css={{
      appearance: 'none',
      cursor: 'pointer',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 'inherit',
      textDecoration: 'none',
      fontSize: 'inherit',
      fontWeight: 'bold',
      px: 4,
      py: 12,
      color: 'white',
      bg: 'primary',
      border: 0,
      borderRadius: 32,
      whiteSpace: 'nowrap',
    }}
  />
));

export default Button;
