import { tint } from 'polished';

export default {
  background: '#FFFFFF',
  text: '#06324C',
  highlight: tint(0.8, '#9FCFEF'),

  primary: '#0070B5',
  secondary: '#06324C',
  blueLight: '#9FCFEF',
  blueLighter: tint(0.8, '#9FCFEF'),
  pink: '#FFB6D9',
  pinkDark: '#EA5798',
  green: '#71CC98',
  greenDark: '#005C50',
  gray: '#58728C',
  grayLight: '#9EA6B4',
  grayLighter: tint(0.9, '#9EA6B4'),
  paleYellow: '#FEDCA9',
  paleRed: '#AE5262',

  border: tint(0.5, '#9EA6B4'),

  danger: '#D0021B',
  error: '#D0021B',

  lowContrast: '#ffffff',
  hiContrast: '#06324C',

  modes: {
    dark: {
      lowContrast: '#06324C',
      hiContrast: '#ffffff',
    },
  },
};
