import buttons from './buttons';
import forms from './forms';
import colors from './colors';
import styles from './styles';

export default {
  useCustomProperties: false,
  breakpoints: ['30em', '48em', '62em', '80em', '105em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'centra-no-2, system-ui, sans-serif',
    heading: 'centra-no-2, system-ui, sans-serif',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 64],
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    heading: '-0.02em',
  },
  radii: [0, 8, 16, '99rem'],
  boxShadow: {
    dropdown: '0 2px 8px rgba(0,0,0,.1)',
    step: 'inset 0 0 0 2px #06324C',
    inputFocus: `0 0 0 1px ${colors.primary}`,
    inputHover: `0 0 0 1px ${colors.grayLight}`,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'heading',
      mt: 4,
      mb: 3,
    },
    title: {
      fontSize: [4, 5],
    },
  },
  layout: {
    container: {
      maxWidth: 1312,
      px: [3, null, null, 4],
    },
    text: {
      maxWidth: '80ch',
    },
    narrow: {
      maxWidth: 1024,
    },
    wide: {
      maxWidth: 1680,
      px: [3, null, null, 4],
    },
    parcel: {
      maxWidth: 1280,
    },
  },
  colors,
  buttons,
  forms,
  styles,
};
