export const isBrowser = typeof window !== 'undefined';

function getDataLayer() {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
}

export const setCookieConsent = (acceptedCategories, createEvent = false) => {
  getDataLayer().push({
    'Cookie Consent': acceptedCategories.join(','),
  });
  if (createEvent) {
    getDataLayer().push({
      event: 'setConsent',
      consent: acceptedCategories.join(','),
    });
  }
};
