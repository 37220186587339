export default {
  common: {
    cursor: 'pointer',
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 'inherit',
    textDecoration: 'none',
    fontSize: 'inherit',
    fontWeight: 'bold',
    px: 3,
    py: 12,
    color: 'white',
    bg: 'primary',
    border: 0,
    borderRadius: 32,
    whiteSpace: 'nowrap',
    ':focus': { boxShadow: 'none', outline: 'none' },
    ':disabled': {
      opacity: 0.35,
      cursor: 'not-allowed',
      ':hover': {
        boxShadow: 'none',
      },
    },
  },
  primary: {
    variant: 'buttons.common',
    ':hover': {
      boxShadow: 'inset 0 0 0 100em rgba(0,0,0,.1)',
    },
  },
  plain: {
    variant: 'buttons.common',
    color: 'primary',
    bg: 'transparent',
    borderRadius: 0,
    px: 0,
    ':hover': {
      color: 'primary',
    },
  },
  light: {
    variant: 'buttons.plain',
    color: 'white',
    ':hover': {
      color: 'blueLight',
    },
  },
  secondary: {
    variant: 'buttons.common',
    color: 'primary',
    bg: 'white',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'grayLight',
    ':hover': {
      borderColor: 'primary',
    },
  },
  ghost: {
    variant: 'buttons.secondary',
    py: 2,
    px: 3,
    color: 'white',
    bg: 'transparent',
    borderColor: 'rgba(255,255,255,.5)',
    ':hover': {
      color: 'white',
      borderColor: 'white',
    },
  },
  small: {
    variant: 'buttons.primary',
    py: 2,
    px: 3,
    fontSize: 1,
  },
};
