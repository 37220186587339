const textInputBase = {
  bg: 'white',
  color: 'secondary',
  borderColor: 'border',
  borderRadius: 1,
  '::-webkit-input-placeholder': {
    color: 'gray',
  },
  '::-moz-placeholder': {
    color: 'gray',
  },
  ':-ms-input-placeholder': {
    color: 'gray',
  },
  '::placeholder': {
    color: 'gray',
  },
  ':focus, :focus:hover': {
    boxShadow: 'inputFocus',
    outline: 'none',
    borderColor: 'primary',
  },
  ':hover': {
    borderColor: 'grayLight',
    boxShadow: 'inputHover',
  },
};

export default {
  input: {
    ...textInputBase,
  },
  select: {
    ...textInputBase,
  },
  label: {
    fontWeight: 'bold',
    div: {
      flex: 'none',
    },
  },
  textarea: { ...textInputBase },
  radio: {},
  checkbox: { mt: '-2px' },
  error: {
    borderColor: 'error',
  },
};
